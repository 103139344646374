<template>
  <div>
    <v-data-table
      ref="table"
      :page="options.page"
      :pageCount="numberOfPages"
      :headers="headers"
      :items="items"
      :sort-by="null"
      :sort-desc="true"
      :options.sync="options"
      :server-items-length="options.total"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      :items-per-page="options.itemsPerPage"
      loading-text="Chargement"
      no-data-text="Pas de données"
      no-results-text="Pas de résultats"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="2"
            ><v-text-field
              v-model="search"
              @change="debouncedGetData"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
              class="ml-2 mb-1 shrink"
            ></v-text-field
          ></v-col>
          <v-col cols="2">
            <v-select
              dense
              single-line
              label="Champ de recherche"
              v-model="searchField"
              :items="searchFields"
              class="mx-0 mb-1 mt-6 shrink"
            ></v-select>
            <!-- class="pt-7 pb-6 mt-0 my-1" -->
            <!--v-btn @click="debouncedUpdateData" class="ma-4"> Valider </v-btn-->
          </v-col>
          <v-col cols="4" class="mt-3"
            ><!--              :initialValue="[]" range="7" -->
            <!-- <date-filter
              periodType="past-and-today"
              format="date"
              :initialValue="dates"
              @valueChanged="setDates"
              dense
              single-line
            ></date-filter> -->
            <date-range-filter
              @valueChanged="setDates"
              periodType="past-and-today"
              format="date"
              :range="30"
              :initialValue="dates"
              :showBtnList="['ALL','LAST_MONTH']"
              :outlined="true"
            ></date-range-filter>
          </v-col>
          <v-col cols="3" class="mt-4">
            <v-autocomplete
              v-model="topics.select"
              :items="topics.items"
              :loading="topics.loading"
              :search-input.sync="topics.search"
              item-text="topic"
              item-value="id"
              cache-items
              class=""
              hide-no-data
              label="Par topic"
              dense
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.img_src="{ item }">
        <a @click="openDialog(item)">
          <v-img
            :src="item.img_src"
            max-height="50px"
            max-width="100px"
          ></v-img>
        </a>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <span v-if="item.title.length > tableTitleMaxLen">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <a @click="openDialog(item)">
                  {{ truncate(item.title, tableTitleMaxLen) }}
                </a>
              </span>
            </template>
            <span>
              {{ item.title }}
            </span>
          </v-tooltip>
        </span>
        <span v-else>
          <a @click="openDialog(item)">{{ item.title }}</a></span
        >
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a :href="item.url" target="_blank" v-bind="attrs" v-on="on">
              {{ getHostFromUrl(item.url) }}
            </a>
          </template>
          <span>{{ item.url }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.content="{ item }">
        {{ truncate(item.content, 30) }}
      </template>
      <!-- <template v-slot:item.action="{ item }">
        <a @click="openDialog(item)">
          <v-icon>
            {{ icons.mdiEye }}
          </v-icon>
        </a>
      </template> -->
    </v-data-table>
    <v-dialog scrollable v-model="dialogVisible" max-width="800">
      <v-card class="dialog-item">
        <span v-show="dialogItem.img_src != ''">
          <v-img
            height="360"
            aspect-ratio="16/9"
            :src="dialogItem.img_src"
          ></v-img>
          <a
            :href="dialogItem.img_src"
            target="_blank"
            class="dialog-image-a mr-1"
          >
            <v-icon class="">
              {{ icons.mdiEye }}
            </v-icon>
          </a>
        </span>
        <v-card-title>
          <span class="headline">{{ dialogItem.title }}</span>
        </v-card-title>
        <v-card-text class="infos">
          <ul>
            <li>
              Url:
              <a :href="dialogItem.url" target="_blank">
                {{ dialogItem.url }}
              </a>
            </li>
            <li>
              Date : {{ dialogItem.date }} ◊◊◊ Discover depuis:
              {{ dialogItem.date_disco }} ◊◊◊ Diff: {{ dialogItem.diff_date }}
            </li>
            <li>Content length : {{ dialogItem.content_length }}</li>
          </ul>

          <v-divider class="mx-4 my-3"></v-divider>

          {{ dialogItem.content }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dialogVisible = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@axios";
import { mdiEye, mdiMagnify } from "@mdi/js";
import debounce from "lodash/debounce";
import { truncate } from "@/utils/formatting";
import DateFilter from "@/components/common/filters/DateFilter";
import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
//import { noDataText } from "@/utils/constants";
// import { formatNumber, formatPercent, truncate } from "@/utils/formatting";
// import { getDateStr } from "@/utils/dates";

export default {
  name: "DiscoverRealTimeUrlsTable",
  props: {
    language: {
      type: String,
      default: "fr",
      validator(value) {
        return ["fr", "en", ""].includes(value);
      },
    },
    initialDates: {
      type: Array,
      default: [],
    },
    accountId: {
      type: Number,
      default: null,
    },
  },
  components: {
    DateFilter,
    DateRangeFilter,
  },
  setup() {},
  created() {
    this.dates = this.initialDates;
    this.debouncedGetData();
    this.debouncedGetTopics();
  },
  data() {
    return {
      // noDataText: "No data",
      dates:[],
      topics: {
        items: [],
        loading: false,
        search: null,
        searchVal: "",
        select: null,
      },
      items: [],
      headers: [
        { text: "Image", value: "img_src", sortable: false },
        { text: "Titre", value: "title", sortable: false },
        { text: "Url", value: "url", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Date Discover", value: "date_disco", sortable: false },
        //{ text: "Date Difference", value: "diff_date", sortable: false },
        { text: "Content length", value: "content_length", sortable: false },
        //{ text: "Content", value: "content", sortable: false },
        //{ text: "Voir", value: "action", sortable: false },
      ],
      searchFields: [
        { text: "Rechercher partout", value: "all" },
        { text: "Rechercher dans le titre", value: "title" },
        { text: "Rechercher dans l'url", value: "url" },
        //{ text: "Rechercher dans la date", value: "date" },
        { text: "Rechercher dans le contenu", value: "content" },
      ],
      searchField: "all",
      dialogVisible: false,
      dialogItem: {
        img_src: "",
        title: "",
        url: "",
        date: "",
        date_disco: "",
        //diff_date: "",
        content_length: "",
        content: "",
      },
      tableTitleMaxLen: 70,
      search: "",
      selectedDates: [],
      loading: false,
      numberOfPages: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      icons: {
        mdiEye,
        mdiMagnify,
      },
    };
  },
  methods: {
    setDates(dates) {
      this.selectedDates = dates;
      this.$emit("periodChange", dates);
      this.debouncedGetData();
    },
    openDialog(item) {
      this.dialogItem = item;
      this.dialogVisible = true;
    },
    getHostFromUrl(url) {
      const oUrl = new URL(url);
      return oUrl.hostname;
    },
    truncate(str, n) {
      return truncate(str, n, "...");
    },
    debouncedGetTopics: debounce(async function () {
      await this.getTopics();
    }, 500),
    async getTopics() {
      const payload = {
        search: this.topics.searchVal,
      };
      const response = await axios.get("google-discover-real-time/topics", {
        params: payload,
      });
      this.topics.items = response.data.items;
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const searchStr = this.search.trim();
      const payload = {
        language: this.language,
        // account_id: this.accountId,
        // dates: this.selectedDates.join(","),
        search_field: this.searchField,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };
      if (this.selectedDates.length == 2) {
        payload["dates"] = this.selectedDates.join(",");
      }
      if (searchStr.length > 2) {
        payload["search"] = searchStr;
      }
      if (this.accountId) {
        payload["account_id"] = this.accountId;
      }
      if (this.topics.select) {
        payload["topic_id"] = this.topics.select;
      }
      
      this.loading = true;
      const response = await axios.get("google-discover-real-time/urls", {
        params: payload,
      });
      this.loading = false;
      this.items = response.data.items;
      this.options.total = response.data.total;
      this.numberOfPages = response.data.total / this.options.itemsPerPage;
      
      this.items = response.data.items;
    },
  },
  computed: {},
  watch: {
    "topics.search":{
      handler(newValue, oldValue) {
        if (newValue && newValue !== this.topics.select) {
          this.topics.searchVal = newValue;
          this.debouncedGetTopics();
        }
      },
    },
    "topics.select":{
      handler(newValue, oldValue) {
        if (newValue !== oldValue){
          if(newValue){
            this.dates = [];
            this.selectedDates = [];
            this.$emit("periodChange", []);
          }
          this.topics.searchVal = this.topics.select;
          this.debouncedGetData();
        }
      },
    },
    language: {
      //immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.debouncedGetData();
      },
    },
    accountId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.debouncedGetData();
      },
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.debouncedGetData();
      },
    },
    searchField: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && this.search.trim() != "")
          this.debouncedGetData();
      },
    },
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.debouncedGetData();
    },
    "options.page"() {
      this.debouncedGetData();
    },
    "options.sortBy"() {
      this.debouncedGetData();
    },
    "options.sortDesc"() {
      this.debouncedGetData();
    },
  },
};
</script>
<style scoped>
.dialog-image-a {
  float: right;
  position: relative;
  top: -30px;
}
</style>
